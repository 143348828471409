@charset "UTF-8";

// IMPPRT INIT

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

@import "lib/_sanitize";    // Reset CSS
@import "_setting";

$type: sp;
@import "_common";
@import "_layout";