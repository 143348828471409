@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none;
  vertical-align: top; }

table {
  border-collapse: collapse; }

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

input::placeholder {
  color: #a2a2a2; }

/* IE */
input:-ms-input-placeholder {
  color: #a2a2a2; }

/* Edge */
input::-ms-input-placeholder {
  color: #a2a2a2; }

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/*ボーダー*/
/*背景グリーン*/
/*ちょっと薄いグリーン*/
/*青*/
/*紫*/
/*薄いグリーン*/
/* font */
/**
* Noto Sans CJK JP: https://www.google.com/get/noto/#sans-jpan
* Noto Sans JP: https://fonts.google.com/earlyaccess#Noto+Sans+JP

@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 100;
	src: local("Noto Sans CJK JP Thin"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 300;
	src: local("Noto Sans CJK JP Light"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
}
*/
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP Regular"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype"); }

/*
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	src: local("Noto Sans CJK JP Medium"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
}
*/
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP Bold"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype"); }

/*
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 900;
	src: local("Noto Sans CJK JP Black"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
}
*/
/* 日本語（notoSans）*/
/* 日本語（notoSerif）*/
/*日本語用（メイリオ）*/
/*英語用*/
body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  background: #f5f8f9;
  padding-bottom: 50px;
  font-size: 15px;
  line-height: 1.4; }

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative; }

.headArea {
  border-bottom: 1px solid #d8e2e5;
  text-align: center;
  position: relative; }
  .headArea .bt_back {
    position: absolute;
    left: 5px;
    top: 12px; }
    .headArea .bt_back a {
      background: #c7eaeb;
      color: #1c9397;
      padding: 4px 10px 7px 30px;
      font-size: 14px;
      text-decoration: none;
      position: relative;
      border-radius: 7px; }
      .headArea .bt_back a:before {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-top: solid 1px #1c9397;
        border-right: solid 1px #1c9397;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        top: 50%;
        margin-top: -6px;
        left: 15px; }
  .headArea .title {
    font-size: 18px;
    color: #1c9397;
    text-align: center;
    padding: 13px 0;
    display: inline-block; }
  .headArea .bt_back + .title {
    margin: 0 90px; }

.footer_nav {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #dde5e7; }
  .footer_nav ul {
    display: flex; }
    .footer_nav ul li {
      list-style: none;
      flex-basis: 25%;
      width: 25%;
      text-align: center; }
      .footer_nav ul li a {
        display: inline-block;
        padding: 32px 15px 3px;
        text-decoration: none;
        color: #b8c7c8; }
        .footer_nav ul li a span {
          display: block;
          font-size: 15px;
          text-align: center; }
      .footer_nav ul li img {
        height: 30px; }
      .footer_nav ul li.nav1 a {
        background: url(../images/common/menu1.png) no-repeat center 7px;
        background-size: 25px; }
      .footer_nav ul li.nav2 a {
        background: url(../images/common/menu2.png) no-repeat center 7px;
        background-size: 25px; }
      .footer_nav ul li.nav3 a {
        background: url(../images/common/menu3.png) no-repeat center 7px;
        background-size: 25px; }
      .footer_nav ul li.nav4 a {
        background: url(../images/common/menu4.png) no-repeat center 7px;
        background-size: 25px; }
      .footer_nav ul li.nav1.on a {
        background: url(../images/common/menu1on.png) no-repeat center 7px;
        background-size: 25px;
        color: #1c9397; }
      .footer_nav ul li.nav2.on a {
        background: url(../images/common/menu2on.png) no-repeat center 7px;
        background-size: 25px;
        color: #1c9397; }
      .footer_nav ul li.nav3.on a {
        background: url(../images/common/menu3on.png) no-repeat center 7px;
        background-size: 25px;
        color: #1c9397; }
      .footer_nav ul li.nav4.on a {
        background: url(../images/common/menu4on.png) no-repeat center 7px;
        background-size: 25px;
        color: #1c9397; }

.content {
  text-align: center;
  padding: 0 15px 15px;
  /* ICON ---------------------------------------- */
  /* BTN ICON ---------------------------------------- */ }
  .content .tit {
    padding: 15px 15px 0;
    color: #1c9397;
    font-size: 16px; }
    .content .tit span {
      vertical-align: middle; }
  .content .row,
  .content .rowIn {
    margin-bottom: 10px;
    text-align: left; }
  .content input[type="text"],
  .content input[type="password"] {
    width: 100%;
    border: 1px solid #1c9397;
    border-radius: 7px;
    padding: 7px; }
  .content input[type="password"] {
    font-family: transition; }
  .content textarea {
    width: 100%;
    border: 1px solid #1c9397;
    border-radius: 7px;
    padding: 7px; }
  .content select {
    min-width: 70px;
    width: 100%;
    border: 1px solid #1c9397;
    border-radius: 7px;
    padding: 7px 15px; }
  .content input.hide {
    display: none; }
  .content .cb_wrap {
    position: relative;
    padding-left: 25px; }
    .content .cb_wrap:before {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      border: 2px solid #1c9397;
      position: absolute;
      left: 0;
      top: 3px; }
    .content .cb_wrap:after {
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-bottom: 2px solid #1c9397;
      border-left: 2px solid #1c9397;
      transform: rotate(-40deg);
      position: absolute;
      top: 11px;
      left: 3px;
      opacity: 0;
      transform-origin: top left; }

@keyframes checkAanim {
  0% {
    opacity: 1; }
  50% {
    opacity: 1;
    height: 4px; }
  100% {
    opacity: 1;
    height: 4px;
    width: 7px; } }
  .content input[type="checkbox"].hide:checked + label:after {
    animation: checkAanim .3s forwards; }
  .content .commonBt_g,
  .content .commonBt_b,
  .content .commonBt_p,
  .content .commonBt_g2 {
    display: block;
    width: 100%;
    background-color: #1c9397;
    color: #fff;
    border: none;
    border-radius: 7px;
    text-align: center;
    padding: 10px 0 12px;
    text-decoration: none;
    cursor: pointer; }
    .content .commonBt_g:active,
    .content .commonBt_b:active,
    .content .commonBt_p:active,
    .content .commonBt_g2:active {
      opacity: 0.75; }
  .content .commonBt_b {
    background-color: #3f96ba; }
  .content .commonBt_p {
    background-color: #616e9e; }
  .content .commonBt_g2 {
    background: #15b3b8; }
  .content .commonBt_gs {
    display: block;
    width: 100%;
    background-color: #f5f8f9;
    color: #1c9397;
    border: 2px solid #1c9397;
    border-radius: 7px;
    text-align: center;
    padding: 6px 0 8px;
    text-decoration: none; }
    .content .commonBt_gs:active {
      opacity: 0.75; }
  .content .commonBt_gt {
    display: block;
    width: 100%;
    background-color: #c7eaeb;
    color: #1c9397;
    border: none;
    border-radius: 7px;
    text-align: center;
    padding: 10px 0 12px;
    text-decoration: none; }
    .content .commonBt_gt:active {
      opacity: 0.75; }
  .content .bt_l2 {
    padding: 5px 0;
    line-height: 1.3; }
  .content .bt_s {
    display: inline-block;
    width: auto;
    padding-left: 30px;
    padding-right: 30px; }
  .content .ico_login {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/common/ico_login.png") no-repeat center center;
    background-size: 20px;
    vertical-align: middle; }
  .content .btico_beginner {
    background-image: url("../images/common/ico_beginner.png");
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px; }
  .content .btico_shinsatsu {
    background-image: url("../images/common/ico_shinsatsu.png");
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px; }
  .content .btico_naishi {
    background-image: url("../images/common/ico_naishi.png");
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px; }
  .content .btico_daicho {
    background-image: url("../images/common/ico_daicho.png");
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px; }
  .content .btico_tel {
    background-image: url("../images/common/ico_tel.png");
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px; }
  .content .bt_back {
    display: inline-block;
    position: relative;
    padding-left: 10px; }
    .content .bt_back:before {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-top: solid 1px #1c9397;
      border-right: solid 1px #1c9397;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      top: 50%;
      margin-top: -6px;
      left: 15px; }

.btn2Col {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }
  .btn2Col div:last-child {
    padding-left: 15px; }
  .btn2Col div.flex_s {
    width: 30%;
    flex-basis: 30%; }
  .btn2Col div.flex_l {
    width: 70%;
    flex-basis: 70%; }

.btn2Col_h {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }
  .btn2Col_h div {
    width: 50%;
    flex-basis: 50%; }
    .btn2Col_h div:first-child {
      padding-right: 7px; }
    .btn2Col_h div:last-child {
      padding-left: 8px; }

.contentIn {
  padding: 15px 0; }

dl.col2 {
  width: 100%;
  display: flex; }
  dl.col2 dt {
    flex-basis: 40%; }
  dl.col2 dd {
    flex-basis: 60%; }

.f_wrap textarea {
  height: 200px; }

.f_wrap .txtConfirm {
  display: block;
  text-align: left; }

p {
  margin-bottom: 25px;
  text-align: left; }
  p .center {
    text-align: center; }

.commonH2 {
  margin-bottom: 15px;
  font-size: 16px;
  color: #1c9397; }

.fz10 {
  font-size: 10px !important; }

.fz11 {
  font-size: 11px !important; }

.fz12 {
  font-size: 12px !important; }

.fz13 {
  font-size: 13px !important; }

.fz14 {
  font-size: 14px !important; }

.fz15 {
  font-size: 15px !important; }

.fz16 {
  font-size: 16px !important; }

.fz17 {
  font-size: 17px !important; }

.fz18 {
  font-size: 18px !important; }

.fz20 {
  font-size: 20px !important; }

.mB5 {
  margin-bottom: 5px !important; }

.mB10 {
  margin-bottom: 10px !important; }

.mB15 {
  margin-bottom: 15px !important; }

.mB20 {
  margin-bottom: 20px !important; }

.mB30 {
  margin-bottom: 30px !important; }

.mB50 {
  margin-bottom: 50px !important; }

.mT5 {
  margin-top: 5px !important; }

.mT10 {
  margin-top: 10px !important; }

.mT15 {
  margin-top: 15px !important; }

.mT20 {
  margin-top: 20px !important; }

.mT30 {
  margin-top: 30px !important; }

.mT50 {
  margin-top: 50px !important; }

a {
  color: #1c9397; }

.center {
  text-align: center !important; }

.left {
  text-align: left !important; }

.right {
  text-align: right !important; }

.line {
  border-bottom: 1px solid #d8e2e5;
  margin-top: 20px;
  margin-bottom: 20px; }

.c_g2 {
  color: #1c9397; }

.red {
  color: #C00; }

.wAll {
  width: calc(100% + 30px);
  margin-left: -15px; }

.w150 {
  width: 150px !important; }

.kiyaku {
  margin-top: 15px;
  margin-bottom: 15px; }
  .kiyaku .kiyaku_in {
    background: #fff;
    padding: 15px;
    text-align: left;
    height: 300px;
    overflow-y: scroll;
    font-size: 15px; }

.common_form p {
  text-align: left;
  margin-bottom: 10px; }

.common_form dl {
  margin-bottom: 10px; }
  .common_form dl dt {
    text-align: left;
    color: #1c9397;
    margin-bottom: 5px; }

.common_form .f_error {
  display: block;
  text-align: left;
  color: #c00; }

.common_form .ex_txt {
  display: block;
  text-align: left;
  font-size: 14px;
  margin-top: 3px; }

.common_form .day_wrap {
  display: flex; }
  .common_form .day_wrap .d_in {
    padding-right: 10px; }

.f_complete .comp_txt {
  text-align: center;
  margin-bottom: 20px; }
  .f_complete .comp_txt img {
    width: 30%;
    max-width: 200px; }
  .f_complete .comp_txt .c_txt {
    display: block;
    text-align: center;
    color: #1c9397;
    font-size: 22px;
    font-weight: bold;
    padding-top: 10px; }

.f_complete .c_txt2 {
  text-align: left; }

.f_settei dl {
  margin-bottom: 15px; }

.f_settei dt {
  font-size: 14px; }

.diary_head {
  width: 100%;
  background: #fff;
  position: relative;
  padding: 0 10px 10px; }
  .diary_head .title {
    color: #000; }
  .diary_head > a {
    position: absolute;
    display: block;
    width: 45px;
    height: 45px;
    padding: 10px;
    right: 5px;
    top: 5px; }
    .diary_head > a img {
      width: 25px;
      height: 25px; }
  .diary_head .day ul {
    display: flex;
    justify-content: space-between; }
    .diary_head .day ul li {
      list-style: none;
      width: 13%;
      position: relative; }
      .diary_head .day ul li a {
        display: block;
        text-decoration: none;
        color: #000; }
        .diary_head .day ul li a span.d1 {
          display: block;
          margin-bottom: 3px; }
        .diary_head .day ul li a span.d2 {
          display: block;
          background: #eff3f4;
          color: #707676;
          width: 100%;
          height: 40px;
          padding: 10px 10px 10px;
          text-align: right; }
      .diary_head .day ul li.on:after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #85cdca transparent;
        transform: rotate(180deg); }
      .diary_head .day ul li.on a span.d2 {
        display: block;
        background: #85cdca;
        color: #fff; }

.diary_index .d_in {
  background: #fff;
  margin-bottom: 15px; }
  .diary_index .d_in input[type="radio"] {
    display: none; }
  .diary_index .d_in .titleArea {
    overflow: hidden;
    cursor: pointer;
    position: relative; }
    .diary_index .d_in .titleArea .txt {
      display: block;
      float: left;
      padding: 30px 0 30px 65px;
      font-size: 18px; }
    .diary_index .d_in .titleArea .ico {
      display: block;
      float: right;
      width: 60px;
      height: 60px;
      background: url("../images/second/d_check.png") no-repeat;
      background-size: 60px;
      position: absolute;
      top: 15px;
      right: 10px; }
    .diary_index .d_in .titleArea:after {
      content: "";
      display: block;
      width: 45px;
      height: 45px;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 45px;
      overflow: hidden; }
  .diary_index .d_in.opened .titleArea .ico {
    display: none; }
  .diary_index .d_in.opened .d_select_in {
    max-height: 100vh;
    opacity: 1; }
  .diary_index .d_in .d_select_in {
    max-height: 0px;
    overflow: hidden;
    transition: all .2s;
    opacity: 0; }
    .diary_index .d_in .d_select_in ul {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      padding-left: 15px; }
      .diary_index .d_in .d_select_in ul li {
        flex-basis: 100px;
        width: 100px;
        margin: 0 1.5% 20px; }
        .diary_index .d_in .d_select_in ul li label {
          display: block;
          padding-top: 100px;
          position: relative; }
          .diary_index .d_in .d_select_in ul li label:before {
            content: "";
            width: 90px;
            height: 90px;
            border-radius: 50px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -50px;
            border: 3px solid #fff; }
        .diary_index .d_in .d_select_in ul li span {
          display: block;
          text-align: center;
          font-size: 14px;
          line-height: 1.3; }
  .diary_index .d_in ul li {
    list-style: none; }

.diary_index .d_select1 .titleArea .txt {
  background: url("../images/second/d_ico1.png") no-repeat 15px center;
  background-size: 45px; }

.diary_index .d_select1 .d_select_in ul li.ds_a label:before {
  background: url("../images/second/d_1a.png") no-repeat center center #edd5d3;
  background-size: 70%; }

.diary_index .d_select1 .d_select_in ul li.ds_b label:before {
  background: url("../images/second/d_1b.png") no-repeat center center #edd5d3;
  background-size: 70%; }

.diary_index .d_select1 .d_select_in ul li.ds_c label:before {
  background: url("../images/second/d_1c.png") no-repeat center center #edd5d3;
  background-size: 70%; }

.diary_index .d_select1 .d_select_in ul li.ds_d label:before {
  background: url("../images/second/d_1d.png") no-repeat center center #edd5d3;
  background-size: 70%; }

.diary_index .d_select1 .d_select_in ul li.ds_e label:before {
  background: url("../images/second/d_1e.png") no-repeat center center #edd5d3;
  background-size: 70%; }

.diary_index .d_select1 #ds1-1:checked ~ .d_select_in li.ds_a label:before {
  border: 3px solid #e87f76; }

.diary_index .d_select1 #ds1-1:checked ~ .titleArea .ico {
  background: url("../images/second/d_1a.png") no-repeat center center #edd5d3;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select1 #ds1-2:checked ~ .d_select_in li.ds_b label:before {
  border: 3px solid #e87f76; }

.diary_index .d_select1 #ds1-2:checked ~ .titleArea .ico {
  background: url("../images/second/d_1b.png") no-repeat center center #edd5d3;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select1 #ds1-3:checked ~ .d_select_in li.ds_c label:before {
  border: 3px solid #e87f76; }

.diary_index .d_select1 #ds1-3:checked ~ .titleArea .ico {
  background: url("../images/second/d_1c.png") no-repeat center center #edd5d3;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select1 #ds1-4:checked ~ .d_select_in li.ds_d label:before {
  border: 3px solid #e87f76; }

.diary_index .d_select1 #ds1-4:checked ~ .titleArea .ico {
  background: url("../images/second/d_1d.png") no-repeat center center #edd5d3;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select1 #ds1-5:checked ~ .d_select_in li.ds_e label:before {
  border: 3px solid #e87f76; }

.diary_index .d_select1 #ds1-5:checked ~ .titleArea .ico {
  background: url("../images/second/d_1e.png") no-repeat center center #edd5d3;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select2 .titleArea .txt {
  background: url("../images/second/d_ico2.png") no-repeat 15px center;
  background-size: 45px; }

.diary_index .d_select2 .d_select_in ul li.ds_a label:before {
  background: url("../images/second/d_2a.png") no-repeat center center #dee8d2;
  background-size: 70%; }

.diary_index .d_select2 .d_select_in ul li.ds_b label:before {
  background: url("../images/second/d_2b.png") no-repeat center center #dee8d2;
  background-size: 70%; }

.diary_index .d_select2 .d_select_in ul li.ds_c label:before {
  background: url("../images/second/d_2c.png") no-repeat center center #dee8d2;
  background-size: 70%; }

.diary_index .d_select2 .d_select_in ul li.ds_d label:before {
  background: url("../images/second/d_2d.png") no-repeat center center #dee8d2;
  background-size: 70%; }

.diary_index .d_select2 #ds2-1:checked ~ .d_select_in li.ds_a label:before {
  border: 3px solid #9cc47a; }

.diary_index .d_select2 #ds2-1:checked ~ .titleArea .ico {
  background: url("../images/second/d_2a.png") no-repeat center center #dee8d2;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select2 #ds2-2:checked ~ .d_select_in li.ds_b label:before {
  border: 3px solid #9cc47a; }

.diary_index .d_select2 #ds2-2:checked ~ .titleArea .ico {
  background: url("../images/second/d_2b.png") no-repeat center center #dee8d2;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select2 #ds2-3:checked ~ .d_select_in li.ds_c label:before {
  border: 3px solid #9cc47a; }

.diary_index .d_select2 #ds2-3:checked ~ .titleArea .ico {
  background: url("../images/second/d_2c.png") no-repeat center center #dee8d2;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select2 #ds2-4:checked ~ .d_select_in li.ds_d label:before {
  border: 3px solid #9cc47a; }

.diary_index .d_select2 #ds2-4:checked ~ .titleArea .ico {
  background: url("../images/second/d_2d.png") no-repeat center center #dee8d2;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 .titleArea .txt {
  background: url("../images/second/d_ico3.png") no-repeat 15px center;
  background-size: 45px; }

.diary_index .d_select3 .d_select_in ul li.ds_a label:before {
  background: url("../images/second/d_3a.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 .d_select_in ul li.ds_b label:before {
  background: url("../images/second/d_3b.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 .d_select_in ul li.ds_c label:before {
  background: url("../images/second/d_3c.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 .d_select_in ul li.ds_d label:before {
  background: url("../images/second/d_3d.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 .d_select_in ul li.ds_e label:before {
  background: url("../images/second/d_3e.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 .d_select_in ul li.ds_f label:before {
  background: url("../images/second/d_3f.png") no-repeat center center #f8edd8;
  background-size: 70%; }

.diary_index .d_select3 #ds3-1:checked ~ .d_select_in li.ds_a label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-1:checked ~ .titleArea .ico {
  background: url("../images/second/d_3a.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 #ds3-2:checked ~ .d_select_in li.ds_b label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-2:checked ~ .titleArea .ico {
  background: url("../images/second/d_3b.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 #ds3-3:checked ~ .d_select_in li.ds_c label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-3:checked ~ .titleArea .ico {
  background: url("../images/second/d_3c.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 #ds3-4:checked ~ .d_select_in li.ds_d label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-4:checked ~ .titleArea .ico {
  background: url("../images/second/d_3d.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 #ds3-5:checked ~ .d_select_in li.ds_e label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-5:checked ~ .titleArea .ico {
  background: url("../images/second/d_3e.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select3 #ds3-6:checked ~ .d_select_in li.ds_f label:before {
  border: 3px solid #e6a11b; }

.diary_index .d_select3 #ds3-6:checked ~ .titleArea .ico {
  background: url("../images/second/d_3f.png") no-repeat center center #f8edd8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 .titleArea .txt {
  background: url("../images/second/d_ico4.png") no-repeat 15px center;
  background-size: 45px; }

.diary_index .d_select4 .d_select_in ul li.ds_a label:before {
  background: url("../images/second/d_4a.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_b label:before {
  background: url("../images/second/d_4b.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_c label:before {
  background: url("../images/second/d_4c.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_d label:before {
  background: url("../images/second/d_4d.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_e label:before {
  background: url("../images/second/d_4e.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_f label:before {
  background: url("../images/second/d_4f.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_g label:before {
  background: url("../images/second/d_4g.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 .d_select_in ul li.ds_h label:before {
  background: url("../images/second/d_4h.png") no-repeat center center #efe5d7;
  background-size: 70%; }

.diary_index .d_select4 #ds4-1:checked ~ .d_select_in li.ds_a label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-1:checked ~ .titleArea .ico {
  background: url("../images/second/d_4a.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-2:checked ~ .d_select_in li.ds_b label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-2:checked ~ .titleArea .ico {
  background: url("../images/second/d_4b.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-3:checked ~ .d_select_in li.ds_c label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-3:checked ~ .titleArea .ico {
  background: url("../images/second/d_4c.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-4:checked ~ .d_select_in li.ds_d label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-4:checked ~ .titleArea .ico {
  background: url("../images/second/d_4d.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-5:checked ~ .d_select_in li.ds_e label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-5:checked ~ .titleArea .ico {
  background: url("../images/second/d_4e.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-6:checked ~ .d_select_in li.ds_f label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-6:checked ~ .titleArea .ico {
  background: url("../images/second/d_4f.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-7:checked ~ .d_select_in li.ds_g label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-7:checked ~ .titleArea .ico {
  background: url("../images/second/d_5g.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select4 #ds4-8:checked ~ .d_select_in li.ds_h label:before {
  border: 3px solid #a98060; }

.diary_index .d_select4 #ds4-8:checked ~ .titleArea .ico {
  background: url("../images/second/d_5h.png") no-repeat center center #efe5d7;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select5 .titleArea .txt {
  background: url("../images/second/d_ico5.png") no-repeat 15px center;
  background-size: 45px; }

.diary_index .d_select5 .d_select_in ul li.ds_a label:before {
  background: url("../images/second/d_5a.png") no-repeat center center #dcece8;
  background-size: 70%; }

.diary_index .d_select5 .d_select_in ul li.ds_b label:before {
  background: url("../images/second/d_5b.png") no-repeat center center #dcece8;
  background-size: 70%; }

.diary_index .d_select5 #ds5-1:checked ~ .d_select_in li.ds_a label:before {
  border: 3px solid #62b5ac; }

.diary_index .d_select5 #ds5-1:checked ~ .titleArea .ico {
  background: url("../images/second/d_5a.png") no-repeat center center #dcece8;
  background-size: 70%;
  border-radius: 45px; }

.diary_index .d_select5 #ds5-2:checked ~ .d_select_in li.ds_b label:before {
  border: 3px solid #62b5ac; }

.diary_index .d_select5 #ds5-2:checked ~ .titleArea .ico {
  background: url("../images/second/d_5b.png") no-repeat center center #dcece8;
  background-size: 70%;
  border-radius: 45px; }

body .modal_calendar {
  padding: 0;
  width: 90%;
  max-width: 600px; }

.mc_head {
  background: #1c9397;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .mc_head .tit {
    font-size: 18px;
    font-weight: normal;
    padding: 10px;
    padding-bottom: 0;
    color: #fff; }
  .mc_head .pWrap {
    width: 40px;
    flex-basis: 40px; }
    .mc_head .pWrap .prev {
      position: relative;
      border: none;
      width: 40px;
      height: 40px;
      background: #1c9397; }
      .mc_head .pWrap .prev:before {
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        top: 50%;
        margin-top: -6px;
        left: 15px; }
  .mc_head .nWrap {
    width: 40px;
    flex-basis: 40px; }
    .mc_head .nWrap .next {
      position: relative;
      border: none;
      width: 40px;
      height: 40px;
      background: #1c9397; }
      .mc_head .nWrap .next:before {
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 50%;
        margin-top: -6px;
        right: 15px; }

.mc_body table, .calendar_wrap table {
  width: 100%; }
  .mc_body table th, .calendar_wrap table th {
    font-weight: normal;
    text-align: center;
    height: 40px;
    background: #eff3f4;
    font-size: 14px;
    border-bottom: 1px solid #d8e2e5; }
    .mc_body table th:first-child, .calendar_wrap table th:first-child {
      border-left: 1px solid #d8e2e5;
      color: #d63557; }
    .mc_body table th:last-child, .calendar_wrap table th:last-child {
      border-right: 1px solid #d8e2e5; }
  .mc_body table td, .calendar_wrap table td {
    border-left: 1px solid #d8e2e5;
    border-bottom: 1px solid #d8e2e5;
    height: 57px;
    background: #fff;
    vertical-align: top; }
    .mc_body table td span.ex, .calendar_wrap table td span.ex {
      display: block;
      float: right;
      width: 15px;
      height: 15px;
      background: url("../images/second/ico_alert.png") no-repeat;
      background-size: 100%;
      margin-top: 5px;
      margin-right: 2px; }
    .mc_body table td.holiday a, .calendar_wrap table td.holiday a {
      color: #d63557; }
    .mc_body table td a, .calendar_wrap table td a {
      display: block;
      width: 100%;
      height: 57px;
      padding: 3px;
      background: url("../images/second/pen.png") no-repeat center bottom;
      background-size: 27px;
      color: #000;
      text-decoration: none; }
    .mc_body table td.no_ex span.ex, .calendar_wrap table td.no_ex span.ex {
      display: none; }
    .mc_body table td.next_m a, .calendar_wrap table td.next_m a {
      color: #b0b0b0; }
    .mc_body table td.no_edit a, .calendar_wrap table td.no_edit a {
      pointer-events: none;
      background: none; }

.mc_body.yoyaku_calendar table td, .calendar_wrap.yoyaku_calendar table td {
  text-align: left; }
  .mc_body.yoyaku_calendar table td a, .calendar_wrap.yoyaku_calendar table td a {
    display: block;
    width: 100%;
    height: 57px;
    padding: 0 10px;
    background: none;
    background-size: 27px;
    color: #000;
    text-decoration: none; }
    .mc_body.yoyaku_calendar table td a:after, .calendar_wrap.yoyaku_calendar table td a:after {
      display: block;
      text-align: center;
      font-size: 20px;
      content: "◯";
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      position: relative;
      top: -3px;
      color: #1c9397;
      font-weight: bold; }
    .mc_body.yoyaku_calendar table td a:active, .calendar_wrap.yoyaku_calendar table td a:active {
      background: #addee0; }
  .mc_body.yoyaku_calendar table td.no_select a, .calendar_wrap.yoyaku_calendar table td.no_select a {
    pointer-events: none;
    background: none; }
    .mc_body.yoyaku_calendar table td.no_select a:after, .calendar_wrap.yoyaku_calendar table td.no_select a:after {
      content: "✕";
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      color: #c2c2c2; }
  .mc_body.yoyaku_calendar table td.holiday, .calendar_wrap.yoyaku_calendar table td.holiday {
    background: #dfe7e9; }
    .mc_body.yoyaku_calendar table td.holiday a, .calendar_wrap.yoyaku_calendar table td.holiday a {
      color: #d63557; }
      .mc_body.yoyaku_calendar table td.holiday a:after, .calendar_wrap.yoyaku_calendar table td.holiday a:after {
        content: "ー";
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        color: #89c5c7; }
  .mc_body.yoyaku_calendar table td.holiday2, .calendar_wrap.yoyaku_calendar table td.holiday2 {
    background-color: #dfe7e9; }
    .mc_body.yoyaku_calendar table td.holiday2 a:after, .calendar_wrap.yoyaku_calendar table td.holiday2 a:after {
      content: "ー";
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      color: #89c5c7; }
  .mc_body.yoyaku_calendar table td.no_ex span.ex, .calendar_wrap.yoyaku_calendar table td.no_ex span.ex {
    display: none; }
  .mc_body.yoyaku_calendar table td.next_m a, .calendar_wrap.yoyaku_calendar table td.next_m a {
    color: #b0b0b0; }
  .mc_body.yoyaku_calendar table td.today, .calendar_wrap.yoyaku_calendar table td.today {
    background: #fff4d7; }

.alert_wrap {
  border: 2px solid #f52b4c;
  border-radius: 10px;
  margin-bottom: 20px; }
  .alert_wrap p {
    padding: 10px 15px 10px 35px;
    background: url("../images/second/ico_alert.png") no-repeat 10px 10px;
    background-size: 20px;
    margin-bottom: 0;
    color: #f52b4c;
    font-size: 15px; }

.common_modal .modal_in .titleArea {
  color: #1c9397;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; }

.common_modal .modal_in .bodyArea {
  max-height: 400px;
  overflow-y: scroll; }

.common_modal .content {
  padding: 0; }

.common_modal .cancel_wrap {
  padding-top: 20px; }
  .common_modal .cancel_wrap .modal_close {
    font-size: 14px; }
  .common_modal .cancel_wrap .modal_close:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/second/close.png") no-repeat center center;
    background-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
    top: -1px; }

.common_box {
  background: #fff;
  padding: 15px; }
  .common_box ul li {
    list-style: none; }
  .common_box .common_box_head {
    border-bottom: 2px solid #1c9397;
    padding-bottom: 12px;
    margin-bottom: 12px; }
    .common_box .common_box_head .txt1 {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      color: #1c9397; }
    .common_box .common_box_head .txt2 {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: bold; }

.select_time li {
  margin-bottom: 7px; }
  .select_time li a {
    display: block;
    width: 100%;
    background-color: #f5f8f9;
    color: #000;
    border: 1px solid #85cdca;
    border-radius: 7px;
    text-align: center;
    padding: 10px 15px 10px;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: left; }
    .select_time li a:active {
      background: #addee0; }
    .select_time li a:after {
      content: "";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-top: solid 2px #1c9397;
      border-right: solid 2px #1c9397;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 50%;
      margin-top: -4px;
      right: 15px; }

.homeBody .content {
  padding: 0; }

.homeBody .headArea {
  text-align: left;
  padding: 15px 10px;
  border-bottom: none; }
  .homeBody .headArea img {
    width: 70%; }

.homeBody .main {
  width: 100%;
  border-bottom: 1px solid #e1e8eb; }
  .homeBody .main img {
    width: 100%; }

.homeBody .btnWrap {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #e9f1f4;
  border-bottom: 1px solid #e1e8eb; }
  .homeBody .btnWrap > div {
    width: 48%; }
    .homeBody .btnWrap > div a {
      font-size: 18px; }

.homeBody .movWrap {
  padding: 20px 0; }
  .homeBody .movWrap h2 {
    color: #1c9397;
    text-align: center;
    font-size: 18px; }
    .homeBody .movWrap h2:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../images/home/ico_mov.png") no-repeat center center;
      background-size: 20px;
      vertical-align: middle;
      margin-right: 7px;
      position: relative;
      top: -1px; }
  .homeBody .movWrap .in {
    overflow: hidden;
    padding: 15px; }
    .homeBody .movWrap .in > div {
      width: 48%; }
      .homeBody .movWrap .in > div img {
        width: 100%; }
      .homeBody .movWrap .in > div .thumbWrap {
        display: block;
        position: relative; }
        .homeBody .movWrap .in > div .thumbWrap:active {
          opacity: 0.75; }
        .homeBody .movWrap .in > div .thumbWrap:after {
          content: "";
          display: block;
          width: 50px;
          height: 50px;
          background: url("../images/home/mov_btn.png") no-repeat center center;
          background-size: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -25px;
          margin-left: -25px; }
    .homeBody .movWrap .in > div:nth-child(2n-1) {
      float: left; }
    .homeBody .movWrap .in > div:nth-child(2n) {
      float: right; }

.homeBody .shinsatsuWrap h2 {
  color: #1c9397;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px; }
  .homeBody .shinsatsuWrap h2:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/home/ico_shinsatsu.png") no-repeat center center;
    background-size: 20px;
    vertical-align: middle;
    margin-right: 7px;
    position: relative;
    top: -1px; }

.homeBody .shinsatsuWrap .in {
  padding: 0 15px; }

/* ココから下には記入禁止 */
.forPC {
  display: none !important; }
