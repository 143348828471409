body{
	font-family: $ff_notoSans;
	font-weight: 400;
	background: $c_bg;
	padding-bottom: 50px;
	font-size: 15px;
	line-height: 1.4;
}

.wrapper{
	max-width: 800px;
	margin: 0 auto;
	position: relative;
}//.wrapper

.headArea{
	border-bottom: 1px solid $c_border;
	text-align: center;
	position: relative;
	.bt_back{
		position: absolute;
		left: 5px;
		top: 12px;
		a{
			background:$c_g1;
			color: $c_base;
			padding: 4px 10px 7px 30px;
			font-size: 14px;
			text-decoration: none;
			position: relative;
			border-radius: 7px;
			&:before{
				content: "";
				display: block;
				position: absolute;
				width: 8px;
				height: 8px;
				border-top: solid 1px $c_base;
				border-right: solid 1px $c_base;
				-webkit-transform: rotate(-135deg);
				transform: rotate(-135deg);
				top: 50%;
				margin-top: -6px;
				left: 15px;
			}
		}
	}//bt_back
	.title{
		font-size: 18px;
		color: $c_base;
		text-align: center;
		padding: 13px 0;
		display: inline-block;
	}
	.bt_back + .title{
		margin: 0 90px;
	}
}//.headArea

.footer_nav{
	width: 100%;
	background:#fff;
	position: fixed;
	bottom:0;
	left: 0;
	border-top: 1px solid #dde5e7;
	ul{
		display: flex;
		li{
			list-style: none;
			flex-basis:25%;
			width: 25%;
			text-align: center;
			a{
				display: inline-block;
				padding: 32px 15px 3px;
				text-decoration: none;
				color: #b8c7c8;
				span{
					display: block;
					font-size: 15px;
					text-align: center;
				}
			}
			img{
				height: 30px;
			}
			&.nav1 a{
				background:url(../images/common/menu1.png) no-repeat center 7px;
				background-size: 25px;
			}
			&.nav2 a{
				background:url(../images/common/menu2.png) no-repeat center 7px;
				background-size: 25px;
			}
			&.nav3 a{
				background:url(../images/common/menu3.png) no-repeat center 7px;
				background-size: 25px;
			}
			&.nav4 a{
				background:url(../images/common/menu4.png) no-repeat center 7px;
				background-size: 25px;
			}
			&.nav1.on a{
				background:url(../images/common/menu1on.png) no-repeat center 7px;
				background-size: 25px;
				color: $c_base;
			}
			&.nav2.on a{
				background:url(../images/common/menu2on.png) no-repeat center 7px;
				background-size: 25px;
				color: $c_base;
			}
			&.nav3.on a{
				background:url(../images/common/menu3on.png) no-repeat center 7px;
				background-size: 25px;
				color: $c_base;
			}
			&.nav4.on a{
				background:url(../images/common/menu4on.png) no-repeat center 7px;
				background-size: 25px;
				color: $c_base;
			}
		}
	}
}//footer_nav

.content{
	text-align: center;
	padding: 0 15px 15px;
	.tit{
		padding: 15px 15px 0;
		color: $c_base;
		font-size: 16px;
		span{
			vertical-align: middle;
		}
	}
	.row,
	.rowIn{
		margin-bottom: 10px;
		text-align: left;
	}
	input[type="text"],
	input[type="password"]{
		width: 100%;
		border: 1px solid $c_base;
		border-radius: 7px;
		padding: 7px;
	}
	input[type="password"]{
		font-family: transition;
	}
	textarea{
		width: 100%;
		border: 1px solid $c_base;
		border-radius: 7px;
		padding: 7px;
	}
	select{
		min-width: 70px;
		width: 100%;
		border: 1px solid $c_base;
		border-radius: 7px;
		padding: 7px 15px;
	}
	input.hide{
		display: none;
	}
	.cb_wrap{
		position: relative;
		padding-left: 25px;
		&:before{
			content: "";
			display: block;
			width: 13px;
			height: 13px;
			border: 2px solid $c_base;
			position: absolute;
			left: 0;
			top: 3px;
		}
		&:after{
			content: "";
			display: block;
			width: 0px;
			height: 0px;
			border-bottom: 2px solid $c_base;
			border-left: 2px solid $c_base;
			transform: rotate(-40deg);
			position: absolute;
			top: 11px;
			left: 3px;
			opacity: 0;;
			transform-origin: top left;
			@keyframes checkAanim{
				0%{
					opacity: 1;
				}
				50%{
					opacity: 1;
					height: 4px;
				}
				100%{
					opacity: 1;
					height: 4px;
					width: 7px;
				}
			}
		}
	}
	input[type="checkbox"].hide:checked{
		& + label:after{
			animation: checkAanim .3s forwards;
		}
	}
	.commonBt_g,
	.commonBt_b,
	.commonBt_p,
	.commonBt_g2{
		display: block;
		width: 100%;
		background-color:$c_base;
		color: #fff;
		border:none;
		border-radius: 7px;
		text-align: center;
		padding: 10px 0 12px;
		text-decoration: none;
		cursor: pointer;
		&:active{
			opacity: 0.75;
		}
	}
	.commonBt_b{
		background-color:$c_b1;
	}
	.commonBt_p{
		background-color:$c_p1;
	}
	.commonBt_g2{
		background:#15b3b8;
	}
	.commonBt_gs{
		display: block;
		width: 100%;
		background-color:$c_bg;
		color: $c_base;
		border:2px solid $c_base;
		border-radius: 7px;
		text-align: center;
		padding: 6px 0 8px;
		text-decoration: none;
		&:active{
			opacity: 0.75;
		}
	}
	.commonBt_gt{
		display: block;
		width: 100%;
		background-color:$c_g1;
		color: $c_base;
		border:none;
		border-radius: 7px;
		text-align: center;
		padding: 10px 0 12px;
		text-decoration: none;
		&:active{
			opacity: 0.75;
		}
	}
	.bt_l2{
		padding: 5px 0;
		line-height: 1.3;
	}
	.bt_s{
		display: inline-block;
		width: auto;
		padding-left: 30px;
		padding-right: 30px;
	}
	/* ICON ---------------------------------------- */
	.ico_login{
		display: inline-block;
		width: 20px;
		height: 20px;
		background:url("../images/common/ico_login.png") no-repeat center center;
		background-size: 20px;
		vertical-align: middle;
	}
	/* BTN ICON ---------------------------------------- */
	.btico_beginner{
		background-image: url("../images/common/ico_beginner.png");
		background-repeat: no-repeat;
		background-position: 15px 50%;
		background-size: 20px;
	}
	.btico_shinsatsu{
		background-image: url("../images/common/ico_shinsatsu.png");
		background-repeat: no-repeat;
		background-position: 15px 50%;
		background-size: 20px;
	}
	.btico_naishi{
		background-image: url("../images/common/ico_naishi.png");
		background-repeat: no-repeat;
		background-position: 15px 50%;
		background-size: 20px;
	}
	.btico_daicho{
		background-image: url("../images/common/ico_daicho.png");
		background-repeat: no-repeat;
		background-position: 15px 50%;
		background-size: 20px;
	}
	.btico_tel{
		background-image: url("../images/common/ico_tel.png");
		background-repeat: no-repeat;
		background-position: 15px 50%;
		background-size: 20px;
	}
	.bt_back{
		display: inline-block;
		position: relative;
		padding-left:10px;
		&:before{
			content: "";
			display: block;
			position: absolute;
			width: 8px;
			height: 8px;
			border-top: solid 1px $c_base;
			border-right: solid 1px $c_base;
			-webkit-transform: rotate(-135deg);
			transform: rotate(-135deg);
			top: 50%;
			margin-top: -6px;
			left: 15px;
		}
	}
}//content

.btn2Col{
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
	div{
		&:last-child{
			padding-left: 15px;
		}
		&.flex_s{
			width: 30%;
			flex-basis:30%;
		}
		&.flex_l{
			width: 70%;
			flex-basis:70%;
		}
	}
}
.btn2Col_h{
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
	div{
		width: 50%;
		flex-basis:50%;
		&:first-child{
			padding-right: 7px;
		}
		&:last-child{
			padding-left: 8px;
		}
	}
}

.contentIn{
	padding: 15px 0;
}


dl.col2{
	width: 100%;
	display: flex;
	dt{
		flex-basis:40%;
	}
	dd{
		flex-basis:60%;
	}
}

.f_wrap{
	textarea{
		height: 200px;
	}
	.txtConfirm{
		display: block;
		text-align: left;
	}
}

p{
	margin-bottom: 25px;
	text-align: left;
	.center{
		text-align: center;
	}
}
.commonH2{
	margin-bottom: 15px;
	font-size: 16px;
	color: #1c9397;
}

.fz10{font-size: 10px!important;}
.fz11{font-size: 11px!important;}
.fz12{font-size: 12px!important;}
.fz13{font-size: 13px!important;}
.fz14{font-size: 14px!important;}
.fz15{font-size: 15px!important;}
.fz16{font-size: 16px!important;}
.fz17{font-size: 17px!important;}
.fz18{font-size: 18px!important;}
.fz20{font-size: 20px!important;}

.mB5{margin-bottom: 5px!important;}
.mB10{margin-bottom: 10px!important;}
.mB15{margin-bottom: 15px!important;}
.mB20{margin-bottom: 20px!important;}
.mB30{margin-bottom: 30px!important;}
.mB50{margin-bottom: 50px!important;}

.mT5{margin-top: 5px!important;}
.mT10{margin-top: 10px!important;}
.mT15{margin-top: 15px!important;}
.mT20{margin-top: 20px!important;}
.mT30{margin-top: 30px!important;}
.mT50{margin-top: 50px!important;}

a{
	color: $c_base;
}

.center{text-align: center!important;}
.left{text-align: left!important;}
.right{text-align: right!important;}

.line{
	border-bottom: 1px solid #d8e2e5;
	margin-top: 20px;
	margin-bottom: 20px;
}

.c_g2{
	color: $c_g2;
}

.red{color: #C00;}

.wAll{
	width: calc(100% + 30px);
	margin-left: -15px;
}
.w150{ width: 150px!important;}
