/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:800px;
$breakpoint_tb		:800px;
$breakpoint_pc		:$breakpoint_sp + 1;


/* Color */
$c_base : #1c9397;/*サイトカラー*/
$c_text : #3e3e3e; /*テキストに使うフォントカラー*/
$c_href : #1c9397; /*テキスト内で使うリンクカラー*/
$c_border : #d8e2e5; /*ボーダー*/

$c_bg : #f5f8f9; /*背景グリーン*/
$c_g1 : #c7eaeb; /*ちょっと薄いグリーン*/
$c_b1 : #3f96ba; /*青*/
$c_p1 : #616e9e; /*紫*/
$c_g2: #1c9397; /*薄いグリーン*/


/* font */
/**
* Noto Sans CJK JP: https://www.google.com/get/noto/#sans-jpan
* Noto Sans JP: https://fonts.google.com/earlyaccess#Noto+Sans+JP

@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 100;
	src: local("Noto Sans CJK JP Thin"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 300;
	src: local("Noto Sans CJK JP Light"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
}
*/
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	src: local("Noto Sans CJK JP Regular"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}
/*
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	src: local("Noto Sans CJK JP Medium"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
}
*/
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	src: local("Noto Sans CJK JP Bold"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
}
/*
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 900;
	src: local("Noto Sans CJK JP Black"),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
}
*/

$ff_notoSans: 'Noto Sans JP', sans-serif;/* 日本語（notoSans）*/
$ff_notoSerif: 'Noto Serif Japanese', serif;/* 日本語（notoSerif）*/
$ff_jp: 'Meiryo','Hiragino Kaku Gothic ProN',sans-serif; /*日本語用（メイリオ）*/
$ff_eng: 'Roboto', 'sans-serif', 'meiryo'; /*英語用*/
