

.kiyaku{
	margin-top: 15px;
	margin-bottom: 15px;
	.kiyaku_in{
		background:#fff;
		padding: 15px;
		text-align: left;
		height: 300px;
		overflow-y: scroll;
		font-size: 15px;
	}
}

.common_form{
	p{
		text-align: left;
		margin-bottom: 10px;
	}
	dl{
		margin-bottom: 10px;
		dt{
			text-align: left;
			color: #1c9397;
			margin-bottom: 5px;
		}
	}
	.f_error{
		display: block;
		text-align: left;
		color: #c00;
	}
	.ex_txt{
		display: block;
		text-align: left;
		font-size: 14px;
		margin-top: 3px;
	}
	.day_wrap{
		display: flex;
		.d_in{
			padding-right: 10px;
		}
	}
}//.common_form

.f_complete{
	.comp_txt{
		text-align: center;
		margin-bottom: 20px;
		img{
			width: 30%;
			max-width: 200px;
		}
		.c_txt{
			display: block;
			text-align: center;
			color: #1c9397;
			font-size: 22px;
			font-weight: bold;
			padding-top: 10px;
		}
	}
	.c_txt2{
		text-align: left;
	}
}//f_complete

.f_settei{
	dl{
		margin-bottom: 15px;
	}
	dt{
		font-size: 14px;
	}
}

.diary_head{
	width: 100%;
	background:#fff;
	position: relative;
	padding: 0 10px 10px;
	.title{
		color: #000;
	}
	&>a{
		position: absolute;
		display: block;
		width: 45px;
		height: 45px;
		padding: 10px;
		right: 5px;
		top: 5px;
		img{
			width: 25px;
			height: 25px;
		}
	}
	.day{
		ul{
			display: flex;
			justify-content: space-between;
			li{
				list-style: none;
				width: 13%;
				position: relative;
				a{
					display: block;
					text-decoration: none;
					color: #000;
					span.d1{
						display: block;
						margin-bottom: 3px;
					}
					span.d2{
						display: block;
						background:#eff3f4;
						color: #707676;
						width: 100%;
						height: 40px;
						padding: 10px 10px 10px;
						text-align: right;
					}
				}
				&.on{
					&:after{
						content: "";
						position: absolute;
						bottom: -7px;
						left: 50%;
						margin-left: -4px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 7px 7px 7px;
						border-color: transparent transparent #85cdca transparent;
						transform:rotate(180deg);
					}
					a{
						span.d2{
							display: block;
							background:#85cdca;
							color: #fff;
						}
					}
				}
			}//li
		}
	}
}//.diary_head

.diary_index{

	.d_in{
		background:#fff;
		margin-bottom: 15px;
		input[type="radio"]{
			display: none;
		}
		.titleArea{
			overflow: hidden;
			cursor:pointer;
			position: relative;

			.txt{
				display: block;
				float: left;
				padding: 30px 0 30px 65px;
				font-size: 18px;
			}
			.ico{
				display: block;
				float: right;
				width: 60px;
				height: 60px;
				background:url("../images/second/d_check.png") no-repeat;
				background-size: 60px;
				position: absolute;
				top: 15px;
				right: 10px;
			}
			&:after{
				content: "";
				display: block;
				width: 45px;
				height: 45px;
				position: absolute;
				top: 10px;
				right: 10px;
				border-radius: 45px;
				overflow: hidden;
			}
		}
		&.opened{
			.titleArea{
				.ico{
					display: none;
				}
			}
			.d_select_in{
				max-height: 100vh;
				opacity: 1;
			}
		}
		.d_select_in{
			max-height: 0px;
			overflow: hidden;
			transition:all .2s;
			opacity: 0;
			ul{
				display: flex;
				justify-content: left;
				flex-wrap: wrap;
				padding-left: 15px;
				li{
					flex-basis:100px;
					width: 100px;
					margin: 0 1.5% 20px;
					label{
						display: block;
						padding-top: 100px;
						position: relative;
						&:before{
							content: "";
							width: 90px;
							height: 90px;
							border-radius: 50px;
							position: absolute;
							top: 0;
							left: 50%;
							margin-left: -50px;
							border: 3px solid #fff;
						}
					}
					span{
						display: block;
						text-align: center;
						font-size: 14px;
						line-height: 1.3;
					}
				}
			}
		}
		ul{
			li{
				list-style: none;
			}
		}
	}//.d_in

	.d_select1{
		.titleArea{
			.txt{
				background: url("../images/second/d_ico1.png") no-repeat 15px center;
				background-size: 45px;
			}
		}
		.d_select_in{
			ul{
				li.ds_a label:before{
					background:url("../images/second/d_1a.png") no-repeat center center #edd5d3 ;
					background-size: 70%;
				}
				li.ds_b label:before{
					background:url("../images/second/d_1b.png") no-repeat center center #edd5d3 ;
					background-size: 70%;
				}
				li.ds_c label:before{
					background:url("../images/second/d_1c.png") no-repeat center center #edd5d3 ;
					background-size: 70%;
				}
				li.ds_d label:before{
					background:url("../images/second/d_1d.png") no-repeat center center #edd5d3 ;
					background-size: 70%;
				}
				li.ds_e label:before{
					background:url("../images/second/d_1e.png") no-repeat center center #edd5d3 ;
					background-size: 70%;
				}
			}
		}
		#ds1-1:checked{
			& ~ .d_select_in li.ds_a label:before{ border: 3px solid #e87f76;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_1a.png") no-repeat center center #edd5d3 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds1-2:checked{
			& ~ .d_select_in li.ds_b label:before{ border: 3px solid #e87f76;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_1b.png") no-repeat center center #edd5d3 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds1-3:checked{
			& ~ .d_select_in li.ds_c label:before{ border: 3px solid #e87f76;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_1c.png") no-repeat center center #edd5d3 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds1-4:checked{
			& ~ .d_select_in li.ds_d label:before{ border: 3px solid #e87f76;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_1d.png") no-repeat center center #edd5d3 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds1-5:checked{
			& ~ .d_select_in li.ds_e label:before{ border: 3px solid #e87f76;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_1e.png") no-repeat center center #edd5d3 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
	}//d_select1

	.d_select2{
		.titleArea{
			.txt{
				background: url("../images/second/d_ico2.png") no-repeat 15px center;
				background-size: 45px;
			}
		}
		.d_select_in{
			ul{
				li.ds_a label:before{
					background:url("../images/second/d_2a.png") no-repeat center center #dee8d2 ;
					background-size: 70%;
				}
				li.ds_b label:before{
					background:url("../images/second/d_2b.png") no-repeat center center #dee8d2 ;
					background-size: 70%;
				}
				li.ds_c label:before{
					background:url("../images/second/d_2c.png") no-repeat center center #dee8d2 ;
					background-size: 70%;
				}
				li.ds_d label:before{
					background:url("../images/second/d_2d.png") no-repeat center center #dee8d2 ;
					background-size: 70%;
				}
			}
		}
		#ds2-1:checked{
			& ~ .d_select_in li.ds_a label:before{ border: 3px solid #9cc47a;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_2a.png") no-repeat center center #dee8d2 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds2-2:checked{
			& ~ .d_select_in li.ds_b label:before{ border: 3px solid #9cc47a;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_2b.png") no-repeat center center #dee8d2 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds2-3:checked{
			& ~ .d_select_in li.ds_c label:before{ border: 3px solid #9cc47a;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_2c.png") no-repeat center center #dee8d2 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds2-4:checked{
			& ~ .d_select_in li.ds_d label:before{ border: 3px solid #9cc47a;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_2d.png") no-repeat center center #dee8d2 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
	}//d_select2

	.d_select3{
		.titleArea{
			.txt{
				background: url("../images/second/d_ico3.png") no-repeat 15px center;
				background-size: 45px;
			}
		}
		.d_select_in{
			ul{
				li.ds_a label:before{
					background:url("../images/second/d_3a.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
				li.ds_b label:before{
					background:url("../images/second/d_3b.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
				li.ds_c label:before{
					background:url("../images/second/d_3c.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
				li.ds_d label:before{
					background:url("../images/second/d_3d.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
				li.ds_e label:before{
					background:url("../images/second/d_3e.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
				li.ds_f label:before{
					background:url("../images/second/d_3f.png") no-repeat center center #f8edd8 ;
					background-size: 70%;
				}
			}
		}
		#ds3-1:checked{
			& ~ .d_select_in li.ds_a label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3a.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds3-2:checked{
			& ~ .d_select_in li.ds_b label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3b.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds3-3:checked{
			& ~ .d_select_in li.ds_c label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3c.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds3-4:checked{
			& ~ .d_select_in li.ds_d label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3d.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds3-5:checked{
			& ~ .d_select_in li.ds_e label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3e.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds3-6:checked{
			& ~ .d_select_in li.ds_f label:before{ border: 3px solid #e6a11b;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_3f.png") no-repeat center center #f8edd8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
	}//d_select3

	.d_select4{
		.titleArea{
			.txt{
				background: url("../images/second/d_ico4.png") no-repeat 15px center;
				background-size: 45px;
			}
		}
		.d_select_in{
			ul{
				li.ds_a label:before{
					background:url("../images/second/d_4a.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_b label:before{
					background:url("../images/second/d_4b.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_c label:before{
					background:url("../images/second/d_4c.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_d label:before{
					background:url("../images/second/d_4d.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_e label:before{
					background:url("../images/second/d_4e.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_f label:before{
					background:url("../images/second/d_4f.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_g label:before{
					background:url("../images/second/d_4g.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
				li.ds_h label:before{
					background:url("../images/second/d_4h.png") no-repeat center center #efe5d7 ;
					background-size: 70%;
				}
			}
		}
		#ds4-1:checked{
			& ~ .d_select_in li.ds_a label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4a.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-2:checked{
			& ~ .d_select_in li.ds_b label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4b.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-3:checked{
			& ~ .d_select_in li.ds_c label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4c.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-4:checked{
			& ~ .d_select_in li.ds_d label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4d.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-5:checked{
			& ~ .d_select_in li.ds_e label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4e.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-6:checked{
			& ~ .d_select_in li.ds_f label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_4f.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-7:checked{
			& ~ .d_select_in li.ds_g label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_5g.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds4-8:checked{
			& ~ .d_select_in li.ds_h label:before{ border: 3px solid #a98060;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_5h.png") no-repeat center center #efe5d7 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
	}//d_select4

	.d_select5{
		.titleArea{
			.txt{
				background: url("../images/second/d_ico5.png") no-repeat 15px center;
				background-size: 45px;
			}
		}
		.d_select_in{
			ul{
				li.ds_a label:before{
					background:url("../images/second/d_5a.png") no-repeat center center #dcece8 ;
					background-size: 70%;
				}
				li.ds_b label:before{
					background:url("../images/second/d_5b.png") no-repeat center center #dcece8 ;
					background-size: 70%;
				}
			}
		}
		#ds5-1:checked{
			& ~ .d_select_in li.ds_a label:before{ border: 3px solid #62b5ac;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_5a.png") no-repeat center center #dcece8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
		#ds5-2:checked{
			& ~ .d_select_in li.ds_b label:before{ border: 3px solid #62b5ac;}
			& ~ .titleArea .ico{
				background:url("../images/second/d_5b.png") no-repeat center center #dcece8 ;
				background-size: 70%;
				border-radius: 45px;
			}
		}
	}//d_select3
}//diary_index

body .modal_calendar{
	padding: 0;
	width: 90%;
	max-width: 600px;
}

.mc_head{
	background:#1c9397;
	color: #fff;
	display: flex;
	justify-content: space-between;
    flex-wrap: wrap;
    .tit{
    	font-size: 18px;
    	font-weight: normal;
    	padding: 10px;
    	padding-bottom: 0;
    	color: #fff;
    }

	.pWrap{
		width: 40px;
		flex-basis:40px;
		.prev{
			position: relative;
			border: none;
			width: 40px;
			height: 40px;
			background:#1c9397;
			&:before{
				content: "";
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-top: solid 2px #fff;
				border-right: solid 2px #fff;
				-webkit-transform: rotate(-135deg);
				transform: rotate(-135deg);
				top: 50%;
				margin-top: -6px;
				left: 15px;
			}
		}
	}//.pWrap
	.nWrap{
		width: 40px;
		flex-basis:40px;
		.next{
			position: relative;
			border: none;
			width: 40px;
			height: 40px;
			background:#1c9397;
			&:before{
				content: "";
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-top: solid 2px #fff;
				border-right: solid 2px #fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				top: 50%;
				margin-top: -6px;
				right: 15px;
			}
		}
	}//.nWrap
}//.mc_head

.mc_body,.calendar_wrap{
	table{
		width: 100%;
		th{
			font-weight: normal;
			text-align: center;
			height: 40px;
			background:#eff3f4;
			font-size: 14px;
			border-bottom: 1px solid #d8e2e5;
			&:first-child{
				border-left: 1px solid #d8e2e5;
				color: #d63557;
			}
			&:last-child{
				border-right: 1px solid #d8e2e5;
			}
		}
		td{
			border-left: 1px solid #d8e2e5;
			border-bottom: 1px solid #d8e2e5;
			height: 57px;
			background:#fff;
			vertical-align: top;
			span.ex{
				display: block;
				float: right;
				width: 15px;
				height: 15px;
				background:url("../images/second/ico_alert.png") no-repeat;
				background-size: 100%;
				margin-top: 5px;
				margin-right: 2px;
			}
			&.holiday{
				a{
					color: #d63557;
				}
			}
			&.holiday2{
				a{
				}
			}
			a{
				display: block;
				width: 100%;
				height: 57px;
				padding: 3px;
				background:url("../images/second/pen.png") no-repeat center bottom;
				background-size: 27px;
				color: #000;
				text-decoration: none;
			}
			&.no_ex{
				span.ex{
					display: none;
				}
			}
			&.next_m{
				a{
					color:#b0b0b0;
				}
			}
			&.no_edit{
				a{
					pointer-events: none;
					background:none;
				}
			}
		}
	}
	&.yoyaku_calendar{
		table{
			th{
				&:first-child{
				}
				&:last-child{
				}
			}
			td{
				text-align: left;
				span.ex{
				}
				a{
					display: block;
					width: 100%;
					height: 57px;
					padding: 0 10px;
					background:none;
					background-size: 27px;
					color: #000;
					text-decoration: none;
					&:after{
						display: block;
						text-align: center;
						font-size: 20px;
						content: "◯";
						font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						position: relative;
						top: -3px;
						color: $c_g2;
						font-weight: bold;
					}
					&:active{
						background:#addee0;
					}
				}
				&.no_select{
					a{
						pointer-events: none;
						background:none;
						&:after{
							content: "✕";
							font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
							color: #c2c2c2;
						}
					}
				}
				&.holiday{
					background:#dfe7e9;
					a{
						color: #d63557;
						&:after{
							content: "ー";
							font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
							color: #89c5c7;
						}
					}
				}
				&.holiday2{
					background-color: #dfe7e9;
					a{
						&:after{
							content: "ー";
							font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
							color: #89c5c7;
						}
					}
				}
				&.no_ex{
					span.ex{
						display: none;
					}
				}
				&.next_m{
					a{
						color:#b0b0b0;
					}
				}
				&.today{
					background:#fff4d7;
					a{

					}
				}
			}
		}
	}
}//mc_body


.alert_wrap{
	border: 2px solid #f52b4c;
	border-radius: 10px;
	margin-bottom: 20px;
	p{
		padding: 10px 15px 10px 35px;
		background:url("../images/second/ico_alert.png") no-repeat 10px 10px;
		background-size: 20px;
		margin-bottom: 0;
		color: #f52b4c;
		font-size: 15px;
	}
}//alert_wrap

.common_modal{
	.modal_in{
		.titleArea{
			color:#1c9397;
			font-size: 16px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 20px;
		}
		.bodyArea{
			max-height: 400px;
			overflow-y:scroll;
		}
	}
	.content{
		padding: 0;
	}
	.cancel_wrap{
		padding-top: 20px;
		.modal_close{
			font-size: 14px;
		}
		.modal_close:before{
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background:url("../images/second/close.png") no-repeat center center;
			background-size: 20px;
			vertical-align: middle;
			margin-right: 10px;
			position: relative;
			top: -1px;
		}
	}
}

.common_box{
	background:#fff;
	padding: 15px;
	ul{
		li{
			list-style: none;
		}
	}
	.common_box_head{
		border-bottom: 2px solid $c_base;
		padding-bottom: 12px;
		margin-bottom: 12px;
		.txt1{
			display: block;
			text-align: center;
			font-weight: bold;
			font-size: 14px;
			color: $c_g2;
		}
		.txt2{
			display: block;
			text-align: center;
			font-size: 18px;
			font-weight: bold;
		}
	}
}//common_box

.select_time{
	li{
		margin-bottom: 7px;
		a{
			display: block;
			width: 100%;
			background-color:$c_bg;
			color: #000;
			border:1px solid #85cdca;
			border-radius: 7px;
			text-align: center;
			padding: 10px 15px 10px;
			text-decoration: none;
			position: relative;
			font-size: 16px;
			font-weight: bold;
			text-align: left;
			&:active{
				background:#addee0;
			}
			&:after{
				content: "";
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-top: solid 2px $c_base;
				border-right: solid 2px $c_base;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				top: 50%;
				margin-top: -4px;
				right: 15px;
			}
		}
	}
}//.select_time

.homeBody{
	.content{
		padding: 0;
	}
	.headArea{
		text-align: left;
		padding: 15px 10px;
		border-bottom: none;
		img{
			width: 70%;
		}
	}
	.main{
		width: 100%;
		border-bottom: 1px solid #e1e8eb;
		img{
			width: 100%;
		}
	}
	.btnWrap{
		display: flex;
		justify-content: space-between;
		padding: 15px;
		background:#e9f1f4;
		border-bottom: 1px solid #e1e8eb;
		&>div{
			width: 48%;
			a{
				font-size: 18px;
			}
		}
	}
	.movWrap{
		padding: 20px 0;
		h2{
			color: #1c9397;
			text-align: center;
			font-size: 18px;
			&:before{
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				background:url("../images/home/ico_mov.png") no-repeat center center;
				background-size: 20px;
				vertical-align: middle;
				margin-right: 7px;
				position: relative;
				top: -1px;
			}
		}
		.in{
			overflow: hidden;
			padding: 15px;
			&>div{
				width: 48%;
				img{
					width: 100%;
				}
				.thumbWrap{
					display: block;
					position: relative;
					&:active{
						opacity: 0.75;
					}
					&:after{
						content: "";
						display: block;
						width: 50px;
						height: 50px;
						background:url("../images/home/mov_btn.png") no-repeat center center;
						background-size: 50px;
						position: absolute;
						top: 50%;
						left: 50%;
						margin-top: -25px;
						margin-left: -25px;
					}
				}
			}
			&>div:nth-child(2n-1){
				float: left;
			}
			&>div:nth-child(2n){
				float: right;
			}
		}
	}
	.shinsatsuWrap{
		h2{
			color: #1c9397;
			text-align: center;
			font-size: 18px;
			margin-bottom: 20px;
			&:before{
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				background:url("../images/home/ico_shinsatsu.png") no-repeat center center;
				background-size: 20px;
				vertical-align: middle;
				margin-right: 7px;
				position: relative;
				top: -1px;
			}
		}
		.in{
			padding: 0 15px;
		}
	}
}//homeBody

/* ココから下には記入禁止 */

.forPC{	@if $type == sp{display: none!important;}}
.forSP{	@if $type == pc{display: none!important;}}